.cls-1 {
  fill: none;
}

.cls-2 {
  clip-path: url(#clip-path);
}

.cls-3 {
  fill: #fff;
}

.cls-4 {
  clip-path: url(#clip-path-2);
}

.cls-5 {
  fill: #22e0cb;
}

.cls-6 {
  fill: #22e0cc;
}

.cls-7 {
  fill: #23dfcd;
}

.cls-8 {
  fill: #23dfce;
}

.cls-9 {
  fill: #24dfcf;
}

.cls-10 {
  fill: #24ded0;
}

.cls-11 {
  fill: #25ded1;
}

.cls-12 {
  fill: #25ded2;
}

.cls-13 {
  fill: #26ddd3;
}

.cls-14 {
  fill: #26ddd4;
}

.cls-15 {
  fill: #27ddd5;
}

.cls-16 {
  fill: #27dcd6;
}

.cls-17 {
  fill: #28dcd7;
}

.cls-18 {
  fill: #28dcd8;
}

.cls-19 {
  fill: #29dbd9;
}

.cls-20 {
  fill: #29dbdb;
}

.cls-21 {
  fill: #29dbdc;
}

.cls-22 {
  fill: #2adadd;
}

.cls-23 {
  fill: #2adade;
}

.cls-24 {
  fill: #2bdadf;
}

.cls-25 {
  fill: #2bd9e0;
}

.cls-26 {
  fill: #2cd9e1;
}

.cls-27 {
  fill: #2cd9e2;
}

.cls-28 {
  fill: #2dd8e3;
}

.cls-29 {
  fill: #2dd8e4;
}

.cls-30 {
  fill: #2ed8e5;
}

.cls-31 {
  fill: #2ed7e6;
}

.cls-32 {
  fill: #2fd7e7;
}

.cls-33 {
  fill: #2fd7e8;
}

.cls-34 {
  fill: #30d6e9;
}

.cls-35 {
  fill: #30d6ea;
}

.cls-36 {
  fill: #00c7cb;
}

.cls-37 {
  clip-path: url(#clip-path-4);
}

.cls-38 {
  fill: #28e4c1;
}

.cls-39 {
  fill: #28e4c0;
}

.cls-40 {
  fill: #28e4bf;
}

.cls-41 {
  fill: #28e4be;
}

.cls-42 {
  fill: #27e3bd;
}

.cls-43 {
  fill: #27e3bc;
}

.cls-44 {
  fill: #27e3bb;
}

.cls-45 {
  fill: #27e3ba;
}

.cls-46 {
  fill: #27e3b9;
}

.cls-47 {
  fill: #27e3b8;
}

.cls-48 {
  fill: #26e3b7;
}

.cls-49 {
  fill: #26e3b6;
}

.cls-50 {
  fill: #26e2b5;
}

.cls-51 {
  fill: #26e2b4;
}

.cls-52 {
  fill: #26e2b3;
}

.cls-53 {
  fill: #26e2b2;
}

.cls-54 {
  fill: #26e2b1;
}

.cls-55 {
  fill: #25e2b0;
}

.cls-56 {
  fill: #25e2af;
}

.cls-57 {
  fill: #25e1ae;
}

.cls-58 {
  fill: #25e1ad;
}

.cls-59 {
  fill: #25e1ac;
}

.cls-60 {
  fill: #25e1ab;
}

.cls-61 {
  fill: #24e1a9;
}

.cls-62 {
  fill: #24e1a8;
}

.cls-63 {
  fill: #24e1a7;
}

.cls-64 {
  fill: #24e1a6;
}

.cls-65 {
  fill: #24e0a5;
}

.cls-66 {
  fill: #24e0a4;
}

.cls-67 {
  fill: #23e0a3;
}

.cls-68 {
  fill: #23e0a2;
}

.cls-69 {
  fill: #23e0a1;
}

.cls-70 {
  fill: #23e0a0;
}

.cls-71 {
  fill: #23e09f;
}

.cls-72 {
  fill: #23df9e;
}

.cls-73 {
  fill: #23df9d;
}

.cls-74 {
  fill: #22df9c;
}

.cls-75 {
  fill: #22df9b;
}

.cls-76 {
  fill: #22df9a;
}

.cls-77 {
  fill: #22df99;
}

.cls-78 {
  fill: #22df98;
}

.cls-79 {
  fill: #22df97;
}

.cls-80 {
  fill: #21de96;
}

.cls-81 {
  fill: #21de95;
}

.cls-82 {
  fill: #21de94;
}

.cls-83 {
  fill: #21de93;
}

.cls-84 {
  fill: #00d4dc;
}

.cls-85 {
  fill: #00d8e3;
}

.cls-86 {
  fill: #00d6cd;
}

.cls-87 {
  fill: #00a1b5;
}

.cls-88 {
  fill: #44b46a;
}

.cls-89 {
  clip-path: url(#clip-path-6);
}

.cls-90 {
  fill: #00cba9;
}

.cls-91 {
  fill: #00cbaa;
}

.cls-92 {
  fill: #00cbab;
}

.cls-93 {
  fill: #00cbac;
}

.cls-94 {
  fill: #00cbad;
}

.cls-95 {
  fill: #00caae;
}

.cls-96 {
  fill: #00caaf;
}

.cls-97 {
  fill: #00cab0;
}

.cls-98 {
  fill: #00cab1;
}

.cls-99 {
  fill: #00cab2;
}

.cls-100 {
  fill: #00cab3;
}

.cls-101 {
  fill: #00cab4;
}

.cls-102 {
  fill: #00cab5;
}

.cls-103 {
  fill: #00c9b6;
}

.cls-104 {
  fill: #00c9b7;
}

.cls-105 {
  fill: #00c9b8;
}

.cls-106 {
  fill: #00c9b9;
}

.cls-107 {
  fill: #00c9ba;
}

.cls-108 {
  fill: #00c9bb;
}

.cls-109 {
  fill: #00c9bc;
}

.cls-110 {
  fill: #00c9bd;
}

.cls-111 {
  fill: #00c9be;
}

.cls-112 {
  fill: #00c8c0;
}

.cls-113 {
  fill: #00c8c1;
}

.cls-114 {
  fill: #00c8c2;
}

.cls-115 {
  fill: #00c8c3;
}

.cls-116 {
  fill: #00c8c4;
}

.cls-117 {
  fill: #00c8c5;
}

.cls-118 {
  fill: #00c8c6;
}

.cls-119 {
  fill: #00c8c7;
}

.cls-120 {
  fill: #00c8c8;
}

.cls-121 {
  fill: #00c7c9;
}

.cls-122 {
  fill: #00c7ca;
}

.cls-123 {
  fill: #00c7cc;
}

.cls-124 {
  fill: #00c7cd;
}

.cls-125 {
  fill: #00d08c;
}

.cls-126 {
  fill: #00806f;
}

.cls-127 {
  fill: #007992;
}

.cls-128 {
  fill: #00bcdb;
}

.cls-129 {
  fill: #00c1b5;
}

.cls-130 {
  "fill":#00bec1,;
}

:root {
  /* TYPOGRAPHY */
  --font-display: 'Montserrat';
  --font-body: 'Montserrat';

  /* COLORS */
  --brand-light: #f4f8fd;
  --brand-base: #37b482;
  --brand-dark: #003c28;
  --brand-secondary: #004b55;
  --brand-highlight: #37b4c8;
  --000: #ffffff;
  --200: #f2f2f2;
  --400: #cccccc;
  --500: #999999;
  --600: #4a4a4a;
  --800: #202020;
  --error: #e74c3c;
  --warning: #febc30;
  --success: #2ecc71;

  --modal-max-width: 570px;
}
